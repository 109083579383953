import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../navbar/Navbar';
import Sidebar from '../sidebar/Sidebar';
import './single.scss';
import axios from 'axios';
import LoadingSpinner from '../../spinner/LoadingSpinner';
import { LoginContext } from '../../../context/loginContext/Context';
function Single() {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState();
    const [comments, setComments] = useState('');
    const [rating, setRating] = useState(null);
    let { userId } = useParams();
    const [isSpinner, setIsSpinner] = useState(false);
    const [success, setSuccess] = useState(null);
    const {user} = useContext(LoginContext);
    const [deleteMess, setDeleteMess] = useState();

    useEffect(() => {
        const url = `${process.env.REACT_APP_SERVER}/api/rating/` + userId;
        axios.get(url)
            .then((res) => {
                setRating(res.data);
                setName(res.data.name);
                setPhone(res.data.phone);
                setComments(res.data.comments)
            })
    }, []);
    const handleUpdate = async () => {
        try {
            const urlUpdate =  process.env.REACT_APP_SERVER + "/api/rating/" + userId;
            setIsSpinner(true);
            const res =  await axios.put(urlUpdate, {
                ...rating,
                name: name,
                phone: phone,
                comments: comments,
                userLocation: user.location
            });
            setIsSpinner(false);
            setSuccess('Update Successful')
        } catch (err) {

        }
    };
    const handleDelete = async () => {
        if (user.isAdmin === true) {
            try {
                const urlDelete =  process.env.REACT_APP_SERVER + "/api/rating/" + userId;
                const res = await axios.delete(urlDelete, {
                    data: {
                        isAdmin: true
                    }
                });
                setDeleteMess('Delete success!');
                setTimeout(()=> {
                    window.location.replace(process.env.REACT_APP_SITE_URL +"/admin/list");
                }, 
                    1000)
            } catch (err) {
                setDeleteMess('Delete fail!');

            }
        }
    }

    return (
        <div className='single'>
            <Sidebar />
            <div className='singleContainer'>
                <Navbar />
                <div className='singleRating'>
                    <h2 className='title'>CUSTOMER RATING</h2>
                    {isSpinner ? <LoadingSpinner /> : null}
                    <p>{success}</p>
                    <p>{deleteMess}</p>

                    <div className='ratingItem'>
                        <ul className='dataList'>
                            <li>
                                <label className='itemTitle'>Name:</label>
                                <input value={name ? name : ''} onChange={e=>setName(e.target.value)}></input>
                            </li>
                            <li>
                                <label className='itemTitle'>Phone:</label>
                                <input placeholder='' value={phone ? phone : ''} onChange={e=>setPhone(e.target.value)}></input>
                            </li>
                            <li>
                                <label className='itemTitle'>Comments:</label>
                                <textarea placeholder='' value={comments ? comments : ''} onChange={e=>setComments(e.target.value)}></textarea>
                            </li>
                            <li>
                                <span className='itemTitle'>Sastified:</span>
                                <span className='itemContent'>{rating?.satisfied}</span>
                            </li>
                            <li>
                                <span className='itemTitle'>Likely:</span>
                                <span className='itemContent'>{rating?.likely}</span>
                            </li>
                            <li>
                                <span className='itemTitle'>Rating:</span>
                                <span className='itemContent'>{rating?.rating}</span>
                            </li>
                            <li>
                                <span className='itemTitle'>Feedback:</span>
                                <span className='itemContent'>{rating?.answer}</span>
                            </li>
                            <li>
                                <span className='itemTitle'>Location:</span>
                                <span className='itemContent'>Eden D{rating?.location}</span>
                            </li>

                        </ul>
                        
                    </div>
                    <div className='updateRating'>
                        <button className='updateButton' onClick={()=> {handleUpdate()}}>
                        Update 
                        </button>
                    </div>
                    {
                        user.isAdmin  ? (
                            <div className='deleteRating'>
                        <button className='deleteRatingButton' onClick={()=> {handleDelete()}}>
                        Delete 
                        </button>
                    </div>
                        ) : null
                    }

                    
                </div>

            </div>
        </div>
    )
}

export default Single