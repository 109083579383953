import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { LoginContext } from '../../../context/loginContext/Context';
import Navbar from '../navbar/Navbar';
import Sidebar from '../sidebar/Sidebar';
import './profile.scss';

function Profile() {
    const { user, dispatch } = useContext(LoginContext);
    const [file, setFile] = useState(null);
    const [displayName, setDisplayName] = useState(user.displayName);
    const [errorMsg, setErrorMsg] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);


    const PF = `${process.env.REACT_APP_SERVER}/api/images`;
    useEffect(() => {

    }, []);
    const handleSubmit = async (e) => {
        e.preventDefault();
        const updateUser = {
            userId: user._id,
            username: user.username,
            displayName: displayName
        };

        if (file) {
            const ext = file.name.split('.').pop();
            const imageExt = ['jpg', 'png', 'jpeg', 'JPG', 'PNG', 'JPEG'];
            const fileSizeKiloBytes = file.size / 1024

            if (imageExt.includes(ext) && fileSizeKiloBytes < 5120) {

                const data = new FormData();
                // const fileName = Date.now() + file.name;
                const fileName = user._id + '.' + ext;
                data.append("file", file, fileName);
                data.append("name", fileName);
                updateUser.profilePicture = fileName;
                try {
                   const upload = await axios.post(process.env.REACT_APP_SERVER + "/api/upload", data);
                   setIsSuccess(true);

                } catch (err) {
                    setIsSuccess(false);
                    setErrorMsg('Error when upload to server, please try again.')
                }
            } else if (!imageExt.includes(ext)) {
                setIsSuccess(false);
                setErrorMsg('Please just choose image file!')
                
            }   else if (fileSizeKiloBytes >= 5120) {
                setIsSuccess(false);
                setErrorMsg('Image file size cannot over 5MB.')
                
            }
        }
        try {
            const res = await axios.put(process.env.REACT_APP_SERVER + "/api/users/" + user._id, updateUser);
            console.log(res);
            dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
            setUpdateSuccess(true);
        } catch (err) {
            console.log(err)
            setIsSuccess(false);
            setErrorMsg('Error when update to server!')
        }
    }

    return (
        <div className='list'>
            <Sidebar />
            <div className='listContainer'>
                <Navbar />
                <div className='profile'>
                    <div className='profileBox'>
                        <h2 className='title'>USER INFO</h2>
                        {updateSuccess ? <p className="success-message">Update user successful</p> : null}
                        <div className='profileInfo'>
                            <div className='profileItem'>
                                <span>USERNAME:</span>
                                <p>{user.username}</p>
                            </div>
                            <div className='profileItem'>
                                <span>EMAIL:</span>
                                <p>{user.email}</p>
                            </div>
                            <div className='profileItem'>
                                <span>DISPLAY NAME:</span>
                                <input type="text" value={displayName}
                                    onChange={(e) => { setDisplayName(e.target.value) }}
                                />
                            </div>
                            <div className='profileItem'>
                                <span>PROFILE PICTURE:</span>
                                <div></div>
                                <input type="file" id="file"
                                    onChange={(e) => {
                                        setFile(e.target.files[0]);
                                    }}
                                />
                            </div>
                            <img src={file ? URL.createObjectURL(file) : (PF + user.profilePicture)} alt="" className="settingImage" />

                        </div>
                        {isSuccess ? <p className="success-message">Upload file successful</p> : null}
                        <p className="error-message">{errorMsg}</p>
                        <button className='profileSubmit'
                            onClick={(e) => handleSubmit(e)}
                        >Submit</button>
                        
                    </div>
                </div>
            </div>
        </div>)
}

export default Profile