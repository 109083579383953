import axios from 'axios';
import React, { useContext } from 'react';
import { LoginContext } from '../../../context/loginContext/Context';
import Navbar from '../navbar/Navbar';
import Sidebar from '../sidebar/Sidebar';
import './setting.scss';
const FileDownload = require('js-file-download');


function Setting({location, setLocation}) {
    const {user} = useContext(LoginContext);

    const downloadUrl = `${process.env.REACT_APP_SERVER}/api/admin/csv`;

    const handleDownload = async () => {
        try {
            axios.post(downloadUrl, {
                userId: user._id
            }).then((res) => {
                FileDownload(res.data, 'file.csv')
            });
            
        } catch (err) {

        };
    }

    return (
        <div className='settings'>
              <Sidebar location={location} setLocation={setLocation}/>
          <div className='settingContainer'>
              <Navbar />
              <div className='settingWrap'>
                <h2>Setting</h2>
                {
                    user?.isAdmin ? (
                        <div className='settingBox'>
                    <span> Download CSV:</span>
                    <button onClick={()=>handleDownload()} className='downloadButton'>Download</button>

                </div>
                    ) : ''
                }
                

              </div>
          </div>
        </div>
      )
}

export default Setting